import { hasNames } from "../utils";
import * as React from "react";

import * as grd from "../styles/grid.module.scss";
import * as styles from "../styles/triple-points.module.scss";

const TriplePoints = ({ points = [] }) => {
	return (
		<div className={hasNames(grd.gridContainer, styles.wrapWrapper)}>
			{points.map((point, i) => {
				return (
					<div className={hasNames(grd.col4, grd.colSm12)} key={"point-" + i}>
						<div className={styles.wrap}>
							{point.image}
							{point.copy}
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default TriplePoints;
