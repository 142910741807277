import { hasNames } from "../utils";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { useRef } from "react";

import ContactUsFooter from "../components/contact-us-footer";
import FiftyFifty from "../components/fifty-fifty";
import Frame from "../components/frame";
import { HeadMeta } from "../components/head-meta";
import InteractiveScroll from "../components/interactive-scroll";
import InternalHero from "../components/internal-hero";
import TriplePoints from "../components/triple-points";

import * as com from "../styles/common.module.scss";
import * as grd from "../styles/grid.module.scss";
import * as typ from "../styles/typography.module.scss";
import * as contactFooter from "../styles/contact-us-footer.module.scss";


import PlaceholderIcon from "../images/placeholder.icon.svg";
import ChartIcon from "../images/chart.icon.svg";
import StockManagmentIcon from "../images/stockmanagment.icon.svg";
import AssistancetIcon from "../images/assistance.icon.svg";
import ConsolidatedIcon from "../images/consolidated.icon.svg";
import DocumentIcon from "../images/document.icon.svg";
import PlanningIcon from "../images/planning.icon.svg";
import TaxPlanningIcon from "../images/taxplanning.icon.svg";
import WealthEducationgIcon from "../images/wealtheducation.icon.svg";
import FamilyServicesIcon from "../images/familyservices.icon.svg";

export const Head = () => {
	return <HeadMeta title="Approach" />;
};

const OurApproachPage = () => {
	const purposeRef = useRef(null);
	const confidenceRef = useRef(null);
	const timeRef = useRef(null);
	const clarityRef = useRef(null);
	const alignmentRef = useRef(null);
	const contactRef = useRef(null);

	const scrollLocations = [
		{ label: "Purpose", ref: purposeRef },
		{ label: "Confidence", ref: confidenceRef },
		{ label: "Time", ref: timeRef },
		{ label: "Clarity", ref: clarityRef },
		{ label: "Aligment", ref: alignmentRef },
		{ label: "Contact", ref: contactRef },
	];

	return (
		<Frame>
			<InteractiveScroll locations={scrollLocations} />

			<InternalHero
				eyebrow={"Our Approach"}
				page={"approachHero"}
				backdrop={
					<StaticImage
						src='../images/heros/cwa-hero-approach.jpg'
						alt="background image"
						aria-hidden="true"
						placeholder="blurred"
						layout="fullWidth"
						loading="eager"
					/>
				}>
				<h1>Our customized approach meets your personal investment philosophy.</h1>
				<p>
				At Cynosure Wealth Advisors, our success depends on taking the time to cultivate a relationship with you.
				We learn what drives you, assist in defining your vision, and tailor solutions to achieve your goals and aspirations.
				</p>
			</InternalHero>

			{/* Triple Points Block */}
			<div className={com.sectionSpacer}>
					<div className={hasNames(grd.container, com.monobrow)}>approach overview</div>
					<h2 className={hasNames(grd.container, com.centerlineHeader)}>
					Simplify your life by streamlining the complexities of wealth management.
					</h2>
				</div>

				<FiftyFifty reverseMobile={false} useRef={purposeRef}>
					<div className="extra-gap">
							<div className={typ.eyebrow}>
							Purpose
							</div>
							<h2 className={com.browedHeader}>
							Define the purpose of your wealth.
							</h2>
							<div className={typ.bodyLargeLight}>
								<p>When it comes to wealth, defining its purpose can be just as important as accumulating it. We know your purpose is unique to you and your values, whether it's pursuing new business ventures, supporting your children's growth, or contributing to charitable causes.</p>
								<p>Through our comprehensive services, we strive to help you define and fulfill the purpose of your wealth.</p>
							</div>
						</div>
						<StaticImage
							src="../images/cwa-purpose.jpg"
							alt="First Security Building"
							aspectRatio={1 / 1}
							layout="constrained"
							width={640}
							height={640}
							quality={85}
							className={com.imageClipped}
						/>
					</FiftyFifty>

				<FiftyFifty reverseMobile={true} useRef={confidenceRef}>
					<StaticImage
						src="../images/cwa-confidence.jpg"
						alt="First Security Building"
						aspectRatio={1 / 1}
						layout="constrained"
						width={640}
						height={640}
						quality={85}
						className={com.imageClippedLeft}
					/>
					<div className="extra-gap">
						<div className={typ.eyebrow}>
						Confidence
						</div>
						<h2 className={com.browedHeader}>
						Have complete confidence in your advisors.
						</h2>
						<div className={typ.bodyLargeLight}>
							<p>Our advisors have deep experience and come from diverse professional backgrounds, having worked at some of the most reputable investment, legal, and tax firms. They have also held senior managerial and investment positions at large family offices.</p>
							<p>With our breadth of experience, our advisors possess a broad range of technical skills to provide integrated wealth planning, giving you the confidence you need to make informed decisions about your wealth.</p>
						</div>
					</div>
				</FiftyFifty>

				<FiftyFifty reverseMobile={false} useRef={timeRef}>
				<div className="extra-gap">
						<div className={typ.eyebrow}>
						time
						</div>
						<h2 className={com.browedHeader}>
						Time is finite. Spend it wisely.
						</h2>
						<div className={typ.bodyLargeLight}>
							<p>Portfolio management can be complex and time consuming. Our advisors streamline this process by serving as your single point of contact, coordinating with a multitude of professionals for your investment, planning, and reporting needs. </p>
							<p>By ensuring that your planning strategies are properly executed, funded, and reported on, we free up your time for the things that matter most to you.</p>
						</div>
					</div>
					<StaticImage
						src="../images/cwa-time.jpg"
						alt="First Security Building"
						aspectRatio={1 / 1}
						layout="constrained"
						width={640}
						height={640}
						quality={85}
						className={com.imageClipped}
					/>
				</FiftyFifty>

				<FiftyFifty reverseMobile={true} useRef={clarityRef}>
					<StaticImage
						src="../images/cwa-clarity.jpg"
						alt="First Security Building"
						aspectRatio={1 / 1}
						layout="constrained"
						width={640}
						height={640}
						quality={85}
						className={com.imageClippedLeft}
					/>
					<div className="extra-gap">
						<div className={typ.eyebrow}>
						clarity
						</div>
						<h2 className={com.browedHeader}>
						Reduce complexity, gain greater rewards.
						</h2>
						<div className={typ.bodyLargeLight}>
							<p>You've likely faced the challenge of tracking and managing your various personal and financial assets.</p>
							<p>We use state-of-the-art consolidated reporting technology to provide you with a clear and comprehensive view of your holdings, making it easy to track your performance and make informed decisions about your wealth.</p>
						</div>
					</div>
				</FiftyFifty>

			{/* Triple Points Block */}
			<div ref={alignmentRef}>
					<div className={hasNames(grd.container, com.monobrow)}>alignment</div>
					<h2 className={hasNames(grd.container, com.centerlineHeader)}>
					One point of contact for a full suite of financial services.
					</h2>
				</div>
				<TriplePoints
					points={[
						{
							image: <ChartIcon />,
							copy: (
								<>Investment advisory, encompassing both liquid and illiquid assets</>
							),
						},
						{
							image: <StockManagmentIcon />,
							copy: <>Single-stock risk management, diversification, and hedging</>,
						},
						{
							image: <TaxPlanningIcon />,
							copy: <>Tax, estate, and philanthropic planning</>,
						},
						{
							image: <WealthEducationgIcon />,
							copy: (
								<>Wealth education and stewardship</>
							),
						},
						{
							image: <FamilyServicesIcon />,
							copy: <>Select family office services</>,
						},
						{
							image: <ConsolidatedIcon />,
							copy: <>Consolidated portfolio reporting</>,
						},
						{
							image: <DocumentIcon />,
							copy: (
								<>Document storage and retention</>
							),
						},
						{
							image: <PlanningIcon />,
							copy: <>Coordination of planning among third-party advisors</>,
						},
						{
							image: <AssistancetIcon />,
							copy: <>Assistance in finding appropriate third-party solution providers</>,
						},
					]}
				/>

			<ContactUsFooter
			ref={contactRef}
			backdrop={
				<StaticImage
					className={contactFooter.image}
					src={`../images/cwa-footer-1.png`}
					alt=""
					aria-hidden="true"
					layout="fullWidth"
					quality={85}
				/>
			}/>
		</Frame>
	);
};

export default OurApproachPage;
