// extracted by mini-css-extract-plugin
export var body = "triple-points-module--body--2755c";
export var bodyLarge = "triple-points-module--bodyLarge--af81c";
export var bodyLargeLight = "triple-points-module--bodyLargeLight--e04f8";
export var bodySmall = "triple-points-module--bodySmall--2dea7";
export var bold = "triple-points-module--bold--88f38";
export var col0 = "triple-points-module--col0--a1bda";
export var col1 = "triple-points-module--col1--9823c";
export var col10 = "triple-points-module--col10--23060";
export var col11 = "triple-points-module--col11--c8443";
export var col12 = "triple-points-module--col12--1b26f";
export var col2 = "triple-points-module--col2--b5fa7";
export var col3 = "triple-points-module--col3--22bb0";
export var col4 = "triple-points-module--col4--869b9";
export var col5 = "triple-points-module--col5--0a3a4";
export var col6 = "triple-points-module--col6--e4907";
export var col7 = "triple-points-module--col7--93154";
export var col8 = "triple-points-module--col8--b6fd5";
export var col9 = "triple-points-module--col9--bab65";
export var colLg0 = "triple-points-module--colLg0--e49af";
export var colLg1 = "triple-points-module--colLg1--015a2";
export var colLg10 = "triple-points-module--colLg10--da736";
export var colLg11 = "triple-points-module--colLg11--4b2e8";
export var colLg12 = "triple-points-module--colLg12--db22c";
export var colLg2 = "triple-points-module--colLg2--41551";
export var colLg3 = "triple-points-module--colLg3--c213c";
export var colLg4 = "triple-points-module--colLg4--c31a2";
export var colLg5 = "triple-points-module--colLg5--e4578";
export var colLg6 = "triple-points-module--colLg6--deba7";
export var colLg7 = "triple-points-module--colLg7--ab625";
export var colLg8 = "triple-points-module--colLg8--8c2c0";
export var colLg9 = "triple-points-module--colLg9--a45cd";
export var colMd0 = "triple-points-module--colMd0--2d784";
export var colMd1 = "triple-points-module--colMd1--d2393";
export var colMd10 = "triple-points-module--colMd10--59899";
export var colMd11 = "triple-points-module--colMd11--5ee80";
export var colMd12 = "triple-points-module--colMd12--af169";
export var colMd2 = "triple-points-module--colMd2--25f5d";
export var colMd3 = "triple-points-module--colMd3--35425";
export var colMd4 = "triple-points-module--colMd4--baa11";
export var colMd5 = "triple-points-module--colMd5--ee4dd";
export var colMd6 = "triple-points-module--colMd6--19ead";
export var colMd7 = "triple-points-module--colMd7--91638";
export var colMd8 = "triple-points-module--colMd8--67c98";
export var colMd9 = "triple-points-module--colMd9--5fdac";
export var colSm0 = "triple-points-module--colSm0--6ed64";
export var colSm1 = "triple-points-module--colSm1--b3339";
export var colSm10 = "triple-points-module--colSm10--90243";
export var colSm11 = "triple-points-module--colSm11--912aa";
export var colSm12 = "triple-points-module--colSm12--67f24";
export var colSm2 = "triple-points-module--colSm2--f3473";
export var colSm3 = "triple-points-module--colSm3--de8cd";
export var colSm4 = "triple-points-module--colSm4--4ce84";
export var colSm5 = "triple-points-module--colSm5--70916";
export var colSm6 = "triple-points-module--colSm6--602e3";
export var colSm7 = "triple-points-module--colSm7--4fcc7";
export var colSm8 = "triple-points-module--colSm8--65743";
export var colSm9 = "triple-points-module--colSm9--5f700";
export var componentError = "triple-points-module--componentError--f0b33";
export var container = "triple-points-module--container--a1c51";
export var eyebrow = "triple-points-module--eyebrow--ac826";
export var filter = "triple-points-module--filter--9c8e3";
export var gridContainer = "triple-points-module--gridContainer--456cd";
export var homepageHeadline = "triple-points-module--homepageHeadline--e4e6a";
export var internalHeadline = "triple-points-module--internalHeadline--7fb2d";
export var italic = "triple-points-module--italic--fed21";
export var link = "triple-points-module--link--fa762";
export var linkSmall = "triple-points-module--linkSmall--bca33";
export var quote = "triple-points-module--quote--58370";
export var wrap = "triple-points-module--wrap--f35de";
export var wrapWrapper = "triple-points-module--wrapWrapper--99cb8";